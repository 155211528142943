import { useEffect, useRef } from "react";
import cls from "classnames";
import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss";

declare module "react" {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchpriority?: "high" | "low" | "auto";
  }
}

interface IImageAnimateProps extends React.AllHTMLAttributes<HTMLDivElement> {
  picture: string;
  video?: string;
  active?: boolean;
  loop?: boolean;
  placeholder?: string;
  className?: string;
  fetchPriority?: "high" | "low" | "auto";
}
const ImageAnimate: React.FC<IImageAnimateProps> = ({
  picture,
  video,
  loop = false,
  active = true,
  placeholder,
  className,
  alt,
  fetchPriority,
  ...props
}) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const videoRefForWeb = useRef(null);
  const imageRefForH5 = useRef(null);

  useEffect(() => {
    if (!inView) return;
    if (video && active) {
      const selector = `video[data-img="${picture}"]`;
      const videoEle = document.querySelector(selector) as HTMLVideoElement;
      if (videoEle) {
        videoEle.src = videoEle.dataset.video as string;
        videoEle.poster = videoEle.dataset.img as string;
        videoEle.load();
      }
    } else {
      const selector = `img[data-src="${picture}"]`;
      const imgEle = document.querySelector(selector) as HTMLImageElement;
      if (imgEle) {
        const src = imgEle.dataset.src as string;
        imgEle.removeAttribute("data-src");
        imgEle.src = src;
      }
    }
  }, [inView, picture, video, active, className, fetchPriority]);

  return (
    <div ref={ref} className={cls(styles.wrapper, className)} {...props}>
      {video ? (
        <video
          data-img={picture}
          data-video={video}
          className={styles.video}
          poster={placeholder}
          muted
          autoPlay
          loop={loop}
          playsInline={true}
          // eslint-disable-next-line react/no-unknown-property
          webkit-playsinline="true"
        />
      ) : (
        // eslint-disable-next-line react/no-unknown-property
        <img alt={alt} src={placeholder} data-src={picture} className={styles.image} fetchpriority={fetchPriority} />
      )}
    </div>
  );
};

export default ImageAnimate;
