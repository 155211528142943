import cls from "classnames";
import { useModuleExposure, useTranslation } from "hooks";
import styles from "./index.module.scss";

const Advantages = () => {
  const { t } = useTranslation();
  const moduleExposureRef = useModuleExposure("Home-Fast facts");

  const advantages: Array<{ title: string; content: string }> = [
    {
      title: t("about-us-company-today-card-1-title"),
      content: t("new-home-case-user-content"),
    },
    {
      title: t("about-us-company-today-card-2-title"),
      content: t("new-home-case-market-content"),
    },
    {
      title: t("about-us-company-today-card-3-title"),
      content: t("new-home-case-account-content"),
    },
    {
      title: t("about-us-company-today-card-4-title"),
      content: t("new-home-case-no1-content"),
    },
    {
      title: t("about-us-company-today-card-5-title"),
      content: t("new-home-case-processed-content"),
    },
  ];
  return (
    <section ref={moduleExposureRef} className={cls(styles.wrapper)}>
      <div className={cls(styles.container, "xt-safe-row")}>
        <h2 className={styles.title}>{t("new-home-case-title")}</h2>
        <p className={styles.subTitle}>{t("new-home-case-subtitle")}</p>
        <div className={cls(styles.advantages, "hidden-mobile")}>
          {advantages.map(({ title, content }, index) => (
            <div key={index} className={styles.advantageWrapper}>
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
          ))}
        </div>
        <div className={cls(styles.advantages, "hidden-pc")}>
          <div className={styles.firstLine}>
            {advantages.slice(0, 3).map(({ title, content }, index) => {
              return (
                <div key={index} className={styles.advantageWrapper}>
                  <h3>{title}</h3>
                  <p>{content}</p>
                </div>
              );
            })}
          </div>
          <div className={styles.secondLine}>
            {advantages.slice(3).map(({ title, content }, index) => {
              return (
                <div key={index} className={styles.advantageWrapper}>
                  <h3>{title}</h3>
                  <p>{content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
