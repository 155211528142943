import classnames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './index.module.scss'

const CSS_PREFIX = 'CurrencyWithFlag'

interface IProps {
  /**
   * 货币
   */
  currencyKey?: string
  /**
   * 货币名称
   */
  currencyLabel?: string | ReactNode
  currency?: string | ReactNode
  id?: string
  iconSrc?: string
}

/**
 * @description 返回 国旗 + 货币币种
 */
export const CurrencyWithFlag: React.FC<IProps> = React.memo(props => {
  const {
    id, currency, currencyKey, currencyLabel,
    iconSrc
  } = props

  return (
    <div id={id} className={classnames(styles.root, `${CSS_PREFIX}-wrapper`)}>
      {!!iconSrc && <img src={iconSrc} alt={currencyKey} className={classnames(styles.iconClassName, `${CSS_PREFIX}-icon`)} />}
      <div className={classnames(styles.label, `${CSS_PREFIX}-currencyWrapper`)}>
        {!!currency && <span className={classnames(styles.currencyName, `${CSS_PREFIX}-currencyName`)}>{currency}</span>}
        {!!currencyLabel && <span className={classnames(styles.currencyLabel, `${CSS_PREFIX}-currencyLabel`)}>{currencyLabel}</span>}
      </div>
    </div>
  )
})
