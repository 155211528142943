import cls from "classnames";
import { useModuleExposure, useTranslation } from "hooks";
import { XTImage } from "components/XTImage";
import { safeParse } from "utils";
import styles from "./index.module.scss";

const Partners = () => {
  const moduleExposureRef = useModuleExposure("Home-Trusted partners");
  const { t } = useTranslation();

  const i18n = {
    bankInfos: safeParse(t("new-home-partners"), []) as Array<{ name: string; image: string; imageH5: string }>,
  };

  return (
    <section ref={moduleExposureRef} className={cls(styles.wrapper)}>
      <div className={cls(styles.container, "xt-safe-row")}>
        <h2 className={styles.title}>{t("new-home-trusted-partners")}</h2>
        <div className={styles.partners}>
          {i18n.bankInfos.map((bankInfo, index) => {
            return (
              <div key={index} className={styles.bankLogoWrapper}>
                <XTImage alt={bankInfo.name} src={bankInfo.imageH5} srcClear={bankInfo.image} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Partners;
