import { forwardRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { EffectCreative, Autoplay } from "swiper/modules";
import styles from "./index.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

const AdaptiveSwiper: React.ForwardRefRenderFunction<
  any,
  {
    children: React.ReactNode[];
    setCurrentActiveIndex: (index: number) => void;
    paginationComp?: React.ReactNode;
    isEnd: boolean;
    isStart: boolean;
  }
> = ({ children, setCurrentActiveIndex, isEnd, isStart }, ref) => {
  const [direction, setDirection] = useState(false);

  useEffect(() => {
    if (isEnd && !isStart) {
      return setDirection(true);
    }
    if (isStart && !isEnd) {
      return setDirection(false);
    }
  }, [isEnd, isStart]);

  return (
    <div className={styles.wrapper}>
      <Swiper
        allowTouchMove={false}
        loop={true}
        speed={800}
        autoplay={{
          delay: 5000,
          reverseDirection: direction,
          disableOnInteraction: false,
        }}
        effect={"creative"}
        modules={[Autoplay, EffectCreative]}
        creativeEffect={{
          prev: {
            opacity: 0,
            shadow: true,
            translate: [1, 0, -400],
            scale: 0.5,
          },
          next: {
            translate: ["100%", 0, 0],
          },
          limitProgress: 10,
          shadowPerProgress: true,
        }}
        onSlideChange={() => {
          if ((ref as React.MutableRefObject<SwiperClass>).current) {
            setCurrentActiveIndex((ref as React.MutableRefObject<SwiperClass>).current.activeIndex);
          }
        }}
        slidesPerView={"auto"}
        onSwiper={(swiper: SwiperClass) => {
          if (ref as React.MutableRefObject<SwiperClass>) {
            (ref as React.MutableRefObject<SwiperClass>).current = swiper;
          }
        }}
      >
        {children.map((child, index) => {
          return (
            <SwiperSlide key={index} className={styles.slideItem}>
              {child}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default forwardRef(AdaptiveSwiper);
