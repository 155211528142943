import React, { useCallback, useMemo, useState } from "react";
import { RatePointProp } from "types/exchangerate";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_AMOUNT, DEFAULT_CURRENCY } from "constants/exchangeRate";
import { formatCurrencyPairsData, getDataByKey } from "utils/exchangerate";
import { getCurrencyInfo, getRateCarousel } from "apis/exchangerate";

interface ExchangeRateStateType {
  /**
   * 当前的汇率对
   */
  urlParsedCurrencies: [string, string];

  /**
   * 接口返回的货币对
   */
  currencyPairsData?: RatePointProp[];
  /**
   * 兑换的金额
   */
  toAmount?: number | string;

  /**
   * 根据当前路由解析出的汇率对
   */
  latestRatePoint?: RatePointProp;

  /**
   * 数字
   */
  amount?: number;

  from: string;
  fromName?: string;
  toName?: string;
  to: string;
}

interface ExchangeRateDispatchType {
  setUrlParsedCurrencies: (from: string, to: string) => void;
}

const INIT_ERROR_MESSAGE = "未成功初始化,请检查ExchangeRateProvider";

const DEFAULT_STATE: ExchangeRateStateType = {
  urlParsedCurrencies: DEFAULT_CURRENCY,
  amount: DEFAULT_AMOUNT,
  from: DEFAULT_CURRENCY[0],
  to: DEFAULT_CURRENCY[1],
};

const DEFAULT_ACTIONS = {
  setUrlParsedCurrencies: () => {
    console.error(INIT_ERROR_MESSAGE);
  },
};

/**
 * @deprecated 推荐useCommonState
 * @type {React.Context<CommonStateType | undefined>}
 */
const ExchangeRateStateContext = React.createContext<ExchangeRateStateType>(DEFAULT_STATE);

const ExchangeRateDispatchContext = React.createContext<ExchangeRateDispatchType>(DEFAULT_ACTIONS);

export function useExchangeRateState() {
  const context = React.useContext(ExchangeRateStateContext);
  if (context === undefined) {
    console.error("useExchangeRateState must be used within a ExchangeRateContextProvider");
    return DEFAULT_STATE;
  }
  return context;
}

export function useExchangeRateDispatch() {
  const context = React.useContext(ExchangeRateDispatchContext);
  if (context === undefined) return DEFAULT_ACTIONS;

  return context;
}

const ExchangeRateContextProvider = ({
  children,
}: ProviderProps) => {

  const [urlParsedCurrencies, setUrlParsedCurrencies] = useState(DEFAULT_CURRENCY)

  // 当前支持的汇率对
  const { data: ratePoints } = useQuery(["getRateCarousel", "HK"], () => getRateCarousel({ siteCode: "HK" }), {
    cacheTime: 0,
    staleTime: 0,
  });

  // 所有币种的信息
  const { data: currencyInfo } = useQuery(["getCurrencyInfo"], getCurrencyInfo,);

  // 完整的币种对信息
  const currencyPairsData = useMemo(() => formatCurrencyPairsData(ratePoints || [], currencyInfo), [ratePoints, currencyInfo]);

  const handleChangeCurrency = useCallback((from: string, to: string) => {
    // 获取最新匹配的币种对
    const _latestRatePoint = getDataByKey(currencyPairsData, from, to);
    // 接口失败的兜底操作
    if (!_latestRatePoint) return;
    setUrlParsedCurrencies([_latestRatePoint.fromCcy, _latestRatePoint.toCcy])
  }, [currencyPairsData])

  const values = useMemo(() => {
    // 获取最新匹配的币种对
    const _latestRatePoint = getDataByKey(currencyPairsData, urlParsedCurrencies[0], urlParsedCurrencies[1]);
    const from = urlParsedCurrencies[0];
    const fromName = _latestRatePoint?.fromCcyName;
    const to = urlParsedCurrencies[1];
    const toName = _latestRatePoint?.toCcyName;

    return ({
      amount: 1,
      from,
      fromName,
      to,
      toName,
      latestRatePoint: _latestRatePoint,
      urlParsedCurrencies: [from, to] as [string, string],
    })
  }, [currencyPairsData, urlParsedCurrencies])

  return (
    <ExchangeRateDispatchContext.Provider value={{ setUrlParsedCurrencies: handleChangeCurrency }}>
      <ExchangeRateStateContext.Provider value={{ ...values, currencyPairsData }}>
        {children}
      </ExchangeRateStateContext.Provider>
    </ExchangeRateDispatchContext.Provider>
  );
};

export default ExchangeRateContextProvider;
