import UserVoiceWeb from "./UserVoiceWeb";
import UserVoiceH5 from "./UserVoiceH5";
import { useTranslation } from "hooks";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

export interface UserDataType {
  customPicture: string;
  avatar: string;
  thinking: string;
  name: string;
  position: string;
}

const UsersVoice = () => {
  const { t } = useTranslation();
  let usersData: UserDataType[] = [];
  try {
    const usersDataMore: UserDataType[] = JSON.parse(t("home-users-voice-more")) || [];
    usersData = JSON.parse(t("home-users-voice")).concat(usersDataMore);
  } catch (error) {
    console.log(error);
  }
  return (
    <>
      <UserVoiceH5 className="hidden-pc" data={usersData} />
      <UserVoiceWeb className="hidden-mobile" data={usersData} />
    </>
  );
};

export default UsersVoice;
