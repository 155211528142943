import cls from "classnames";
import { useGoLogin, useModuleExposure, useTranslation } from "hooks";
import { useCommonState } from "context";
import styles from "./index.module.scss";
import ContactUSButton from "components/CusButton";
import { CusBtnSourceEnum } from "constants/enum";
import { Button } from "xt-design";
import ExchangeCarousel from "components/ExchangeRate/ExchangeCarousel";
import ExchangeRateContextProvider from "context/exchangeRate";

interface OwnProps {
  title?: string;
  desc?: string;
  leftClassName?: string;
}

const ExchangeTool = (props: OwnProps) => {
  const { t } = useTranslation();
  const moduleExposureRef = useModuleExposure("Home-ExchangeTool");
  const { isLanding, isGlobal } = useCommonState();
  const handleGoLogin = useGoLogin();

  return (
    <ExchangeRateContextProvider>
      <div className={cls("xt-safe-row", styles.exchangeTool)} ref={moduleExposureRef}>
        <div className={`${styles.left} ${props.leftClassName || ""}`}>
          <div className={styles.content}>
            <h2 className={styles.title}>{props.title || t("XTS-18935.ExchangeTool.title")}</h2>
            <p className={`${styles.desc} p`}>{props.desc || t("XTS-18935.ExchangeTool.desc")}</p>
          </div>
          {isLanding || !isGlobal ? (
            <ContactUSButton className={styles.button} source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""} />
          ) : (
            <Button type="primary" onClick={handleGoLogin} className={styles.button}>
              {t("common-sign-up-now")}
            </Button>
          )}
        </div>
        <ExchangeCarousel className={styles.right} />
      </div>
    </ExchangeRateContextProvider>
  );
};

export default ExchangeTool;
