import { useRef, useState, useEffect } from "react";
import cls from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import type { SwiperClass, SwiperProps } from "swiper/react";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
import { useModuleExposure, useGoLogin, useTranslation } from "hooks";
import { useCommonState } from "context";
import ContactUSButton from "components/CusButton";
import { Button } from "components/Button";
import SlideCard from "./components/SlideCard";
import styles from "./index.module.scss";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { CusBtnSourceEnum } from "../../../constants/enum";

const Advantages = () => {
  const { t } = useTranslation();
  const { isLanding, isGlobal } = useCommonState();
  const moduleExposureRef = useModuleExposure("Home-Advantages");
  const handleGoLogin = useGoLogin();
  const swiperRef = useRef<SwiperClass | null>(null);
  const paginationRef = useRef<HTMLDivElement | null>(null);
  const [activePageNum, setActivePageNum] = useState(0);
  const { isMobile } = useCommonState();
  const duration = isMobile ? 5 : 7;

  useEffect(() => {
    let dom: HTMLSpanElement | null;
    if (paginationRef.current) {
      dom = paginationRef.current.querySelector(`[data-index="${activePageNum}"]`);
      if (dom) {
        dom.style.transition = `transform ${duration}s`;
        dom.style.transform = "translateX(0)";
      }
    }
    return () => {
      if (dom) {
        dom.style.transition = "unset";
        dom.style.transform = "translateX(-100%)";
      }
    };
  }, [activePageNum]);

  const onAutoplayTimeLeft: SwiperProps["onAutoplayTimeLeft"] = (s: SwiperClass) => {
    setActivePageNum(s.activeIndex);
  };

  const togglePageNum = (index: number) => () => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setActivePageNum(index);
    }
  };

  const data: Array<{
    title: string;
    desc: string;
    picture: string;
    pictureH5: string;
    video: string;
    btnText: string;
    btnTextMobile: string;
  }> = [
    {
      title: t("home-advantages-reliable-title"),
      desc: t("home-advantages-reliable-desc"),
      picture: "https://static.xtransfer.com/boss/static/reliable_1aacc93a327da6ae.png",
      pictureH5: "https://static.xtransfer.com/boss/static/Reliable_0a6d8270a9a1a467.png",
      video: "https://static.xtransfer.com/boss/static/reliable_f0bc86a88f331721.mp4",
      btnText: t("home-advantages-reliable-btn-text"),
      btnTextMobile: t("common-sign-up-now"),
    },
    {
      title: t("home-advantages-fast-title"),
      desc: t("home-advantages-fast-desc"),
      picture: "https://static.xtransfer.com/boss/static/fast-first-2x_81780a4e9e358f22.png",
      pictureH5: "https://static.xtransfer.com/boss/static/Fast_62cc1947786bc517.png",
      video: "https://static.xtransfer.com/boss/static/fast-mp4_2d2ca7a6a59bc507.mp4",
      btnText: t("home-advantages-fast-btn-text"),
      btnTextMobile: t("home-advantages-fast-mobile-btn-text"),
    },
    {
      title: t("home-advantages-save-title"),
      desc: t("home-advantages-save-desc"),
      picture: "https://static.xtransfer.com/boss/static/save_575cab424226f6fd.png",
      pictureH5: "https://static.xtransfer.com/boss/static/Save_9cdeec576671e9d2.png",
      video: "https://static.xtransfer.com/boss/static/3.Save_973bf3b94d9c8e68.mp4",
      btnText: t("home-advantages-save-btn-text"),
      btnTextMobile: t("home-advantages-save-mobile-btn-text"),
    },
    {
      title: t("home-advantages-simple-title"),
      desc: t("home-advantages-simple-desc"),
      picture: "https://static.xtransfer.com/boss/static/simple_5d5c52f12decb738.png",
      pictureH5: "https://static.xtransfer.com/boss/static/Simple_eb42a8bcad02d02c.png",
      video: "https://static.xtransfer.com/boss/static/4.simple_bfdd7456b33c225d.mp4",
      btnText: t("home-advantages-simple-btn-text"),
      btnTextMobile: t("home-advantages-simple-mobile-btn-text"),
    },
  ];

  return (
    <div ref={moduleExposureRef} className={styles.container}>
      <Swiper
        effect={"fade"}
        autoplay={{
          delay: duration * 1000,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        fadeEffect={{ crossFade: true }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSwiper={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
      >
        {data.map(({ title, desc, picture, pictureH5, btnTextMobile, video }, index) => {
          return (
            <SwiperSlide key={index}>
              <SlideCard
                active={index === activePageNum}
                title={title}
                content={desc}
                image={picture}
                imageH5={pictureH5}
                video={video}
                actions={() =>
                  isLanding || !isGlobal ? (
                    <ContactUSButton style={{ borderRadius: "99px" }} source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""} />
                  ) : (
                    <Button type="primary" onClick={handleGoLogin}>
                      {btnTextMobile}
                    </Button>
                  )
                }
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={cls("xt-safe-row", styles.paginationContainer)}>
        <div className={styles.left} />
        <div className={styles.right}>
          <div className={styles.pageNums} ref={paginationRef}>
            {data.map(({ btnText }, index) => {
              return (
                <span
                  key={index}
                  className={cls(styles.pageItem, {
                    [styles.active]: index === activePageNum,
                  })}
                  onClick={togglePageNum(index)}
                >
                  <span data-index={index} className={styles.pageItemInner} />
                  {btnText}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
