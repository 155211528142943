export {
  IconPPZar1,
  IconPPIdr1,
  IconPPKrw1,
  IconPPNzd1,
  IconPPSgd1,
  IconPPSek1,
  IconPPNok1,
  IconPPDkk1,
  IconPPChf1,
  IconPPCad1,
  IconPPAud1,
  IconPPJpy1,
  IconPPCny1,
  IconPPEur1,
  IconPPGbp1,
  IconPPHkd1,
  IconPPUsd1,
  IconMABillcollection2,
  IconXThailand1 as IconPPThb1,
  IconXMalaysia1 as IconPPMyr1,
  IconXTurkey1 as IconPPTry1,
  IconXSaudiarabia1 as IconPPSar1,
  IconXArabemirates1 as IconPPAed1,
  IconXIsrael1 as IconPPIls1,
  IconXRomania1 as IconPPRon1,
  IconXPoland1 as IconPPPln1,
  IconXHungary1 as IconPPHuf1,
  IconXCzechrepublic1 as IconPPCzk1,
} from '@xt/react-icon'

export { CurrencyWithFlag } from './CurrencyWithFlag'
