import { Get } from "utils/api";
import { RatePointProp } from '../types/exchangerate';

//region 汇率计算器

export interface RateCarouselParams {
  siteCode: 'HK'
}

/**
 * 非登陆态币种对汇率接口
 */
// https://yapi.xtrfr.cn/project/24/interface/api/79631
export function getRateCarousel({ siteCode }: RateCarouselParams) {
  return Get<RatePointProp[]>("/fund/fx/rate/carousel/open", { siteCode: siteCode });
}
//endregion

//region 非登陆态汇率图接口 汇率图
export interface GraphPresentRateRequestProps {
  type: 'DAY' | 'WEEK' | 'MONTH'
  fromCcy: string
  toCcy: string
  siteCode: 'HK'
}

export interface GraphPresentRateResponseProps {
  ratePointList?: {
    rateTime: string
    rate: string
    fromCcy: string
    toCcy: string
  }[]
  fromCcy: string
  toCcy: string
}

/**
 * 非登陆态汇率图接口
 */
// https://yapi.xtrfr.cn/project/24/interface/api/108895
export function getGraphPresentRate(params: GraphPresentRateRequestProps) {
  return Get<GraphPresentRateResponseProps>("/spotfx/graph/present-rate/get/open", params);
}
//endregion

//region 币种基础信息
export interface CurrencyInfoResponseProps {
  /**
   *   三位币种code
   *   @example USD
   */
  currencyCode:	string
  /**
   * 币种名称
   */
  currencyName:	string
  /**
   * 币种符号
   * @example $
   */
  currencySymbol:	string
  /**
   * 币种图标cdn地址
   */
  currencyIcon:	string
  /**
   * 币种小数精度
   */
  currencyPrecision:	number
}


/**
 * 币种元数据（币种code、精度、图标等）接口
 */
// https://yapi.xtrfr.cn/project/24/interface/api/108887
export function getCurrencyInfo() {
  return Get<CurrencyInfoResponseProps[]>("/fund/fx/currencyInfo");
}
//endregion
