import React, { Fragment, useState } from 'react'
import { Button, ButtonProps, Dropdown, Empty, Icon, IconographNoData, Input, Menu, MenuProps, XtDrawer } from 'xt-design'
import { CurrencyWithFlag } from '../CurrencyWithFlag'
import styles from './index.module.scss'
import Highlighter from "react-highlight-words";
import { useCommonState } from 'context';
import { IconMACheckmark1,IconMACancellation1 } from '@xt/react-icon'
import { useTranslation } from 'hooks';

interface SearchMenuProps {
  items: { label: string, key: string, icon?: string }[]
  searchWords?: string[]
  onChange?: (val: string) => void
  value: string
  placeholder?: string
  emptyDescription?: string
}

const SearchMenu = (props: SearchMenuProps) => {
  const [searchValue, setSearchValue] = useState<string | null | undefined>()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toUpperCase())
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    e.key !== props.value && props.onChange?.(e.key)
  }

  const filterData = !searchValue ? props.items : props.items.filter(i => i.key.includes(searchValue))

  return (
    <div className={styles.searchMenu}>
      <div style={{ padding: '8px 8px 0' }}>
        <Input.Search allowClear placeholder={props.placeholder} onChange={handleSearchChange} />
      </div>
      {
        filterData.length === 0 ?
          <Empty iconographData={<IconographNoData />} description={props.emptyDescription} mode='bold' style={{ marginTop: 48, marginBottom: 48 }} />
          :
          <Menu onClick={handleMenuClick} selectedKeys={[props.value]}>
            {
              filterData.map(i => (
              <Menu.Item key={i.key}>{
                <CurrencyWithFlag
                  currencyLabel={i.label}
                  currencyKey={i.key}
                  iconSrc={i.icon}
                  currency={
                    <Highlighter
                      highlightClassName={styles.highlight}
                      searchWords={searchValue ? [searchValue] : []}
                      autoEscape={false}
                      textToHighlight={i.key}
                    />
                  }
                />
              }</Menu.Item>))
            }
          </Menu>
      }
    </div>
  )
}

interface InnerButtonProps extends Pick<ChangeCurrencyProps, 'currentCurrency'> {
  visible?: boolean
  currentIcon?: string
}

const InnerButton = ({ currentCurrency, currentIcon, visible, onClick }: InnerButtonProps & Pick<ButtonProps, 'onClick'>) => {
  return (
    <Button className={styles.button} onClick={onClick} size={'small'}>
      <CurrencyWithFlag currency={currentCurrency} iconSrc={currentIcon} />
      <Icon
        name='DropdownArrow'
        style={{ verticalAlign: 'middle', marginLeft: '8px' }}
        size={24} rotate={visible ? 0 : 180}
      />
    </Button>)
}

interface ChangeCurrencyProps {
  options: { label: string, key: string, icon?: string }[]
  currentCurrency: string
  currentCurrencyIcon?: string
  onChange?: (val: string) => void
}

const ChangeCurrency = (props: ChangeCurrencyProps) => {
  const { options, currentCurrency, currentCurrencyIcon } = props
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const { isMobile } = useCommonState()

  const onOpen = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const handleClick = (selected: string) => {
    props.onChange?.(selected)
    setVisible(false)
  }

  if (isMobile) {
    return <Fragment>
      <InnerButton currentCurrency={currentCurrency} visible={visible} onClick={onOpen} currentIcon={currentCurrencyIcon} />
      <XtDrawer
        title={<span className={styles.drawerTitle}>{t('exchange-rate-carousel-drawer-title')}</span>}
        extra={<IconMACancellation1 fillColor={'#999999'} size={'4.27vw'} onClick={onClose} />}
        closable={false}
        height={'85%'}
        className={styles.drawer}
        placement='bottom'
        showFooter={false}
        onClose={onClose}
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        onConfirm={() => {
          setVisible(false)
        }}
      >
        {
          options.map(i => (
            <div key={i.key} className={styles.item} onClick={() => handleClick(i.key)}>
              <CurrencyWithFlag currencyKey={i.key} currency={i.key} currencyLabel={i.label} iconSrc={i.icon} />
              {currentCurrency === i.key && <IconMACheckmark1 fillColor={'#FF7A00'} className={styles.icon} />}
            </div>
          ))
        }
      </XtDrawer>
    </Fragment>
  }

  return <>
    <Dropdown
      placement='bottomRight' trigger={['click']}
      overlay={<SearchMenu items={options} value={currentCurrency} onChange={handleClick} placeholder={t('exchange-rate-carousel-search-input-placeholder')} emptyDescription={t('exchange-rate-carousel-search-input-empty')}/>}
      onVisibleChange={v => setVisible(v)}>
      <InnerButton currentCurrency={currentCurrency} visible={visible} onClick={onOpen} currentIcon={currentCurrencyIcon} />
    </Dropdown>
  </>
}

export default ChangeCurrency
