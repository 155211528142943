import { forwardRef, useRef, useState } from "react";
import cls from "classnames";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import styles from "./index.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

const AdaptiveSwiper: React.ForwardRefRenderFunction<unknown, {
    children: React.ReactNode[];
    onChange?: (index: number) => void;
    paginationComp?: React.ReactElement;
    className?: string;
    previewCount?: number;
  }
> = ({ children, onChange, paginationComp, className, previewCount = 2 }, ref) => {
  const swiperRef = useRef<SwiperClass | null>(null);
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number>(0);

  let pageComp = (
    <div className={`${styles.paginationWrapper} pagination-wrapper`}>
      <div className={`${styles.pagination} pagination`}>
        {children.map((_, index) => (
          <div
            key={index}
            className={cls(`${styles.paginationItem} pagination-item`, index === currentActiveIndex && styles.paginationItemActive)}
            onClick={() => {
              swiperRef.current?.slideTo(index);
            }}
          />
        ))}
      </div>
    </div>
  );

  if (paginationComp) {
    pageComp = paginationComp;
  }
  return (
    <div className={cls(styles.wrapper, className)}>
      <Swiper
        className={styles.swiper}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        centeredSlides={true}
        onSlideChange={() => {
          if (swiperRef.current?.realIndex === undefined) return;
          setCurrentActiveIndex(swiperRef.current?.realIndex)
        }}
        slidesPerView={"auto"}
        onSwiper={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
          swiper.slideTo(previewCount); // 因为 slidesPerView 为 auto，所以需要手动设置初始位置
        }}
      >
        {children.map((item, index) => (
          <SwiperSlide key={index} className={styles.slideItem}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
      {pageComp}
    </div>
  );
};

export default forwardRef(AdaptiveSwiper);
