import { useRouter } from "next/router";
import Link from "next/link";
import HeadBanner from "components/HeadBanner";
import XTCard from "components/XTCard";
import UsersVoice from "components/UsersVoice";
import { useTranslation } from "hooks";
import Partners from "./Partners";
import Services from "./Services";
import Cases from "./Cases";
import Advantages from "./Advantages";
import { useCommonState } from "context";
import ContactUSButton from "components/CusButton";
import { Button } from "components/Button";
import { getPathWithQuery, getPathWithSite } from "utils/route";
import { goRegister } from "hooks/useGoLogin";
import { INTERNATIONAL_SUPPORT_CONTACT_US } from "constants/router";
import styles from "./index.module.scss";
import { CusBtnSourceEnum } from "constants/enum";
import { SitesEnum } from "constants/regions";
import ExchangeTool from "./ExchangeTool";

export const ContactUsLink = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { asPath, query } = router;
  const { site } = query as { site: SitesEnum };
  return (
    <Link style={{ color: "#222" }} href={getPathWithQuery({ asPath, toPath: getPathWithSite(site, INTERNATIONAL_SUPPORT_CONTACT_US) })}>
      {children}
    </Link>
  );
};

const Home: React.FC<PageProps> = () => {
  const { t } = useTranslation();
  const { isLanding, isGlobal, isUK, isUS } = useCommonState();

  const i18n: { [key: string]: string } = {
    title: t("new-home-banner-title"),
    bannerImage: t("new-home-banner-image"),
    bannerImageH5: t("new-home-banner-image-h5"),
    bannerVideo: t("new-home-banner-video"),
  };

  return (
    <>
      <HeadBanner
        asHead={true}
        reverse={false}
        moduleName="Home-Header"
        title={i18n.title}
        image={i18n.bannerImage}
        imageH5={i18n.bannerImageH5}
        video={i18n.bannerVideo}
        fetchPriority="high"
        loop={true}
        actions={() =>
          isGlobal ? (
            <>
              <Button onClick={goRegister} type="primary">
                {t("common-get-started")}
              </Button>
              <Button className={styles.contactBtn} style={{ marginLeft: "20px" }}>
                <ContactUsLink>{t("new-home-contact-us")}</ContactUsLink>
              </Button>
            </>
          ) : (
            <ContactUSButton style={{ borderRadius: "99px" }} source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""} />
          )
        }
      />
      <Partners />
      <Advantages />
      <Services />
      {isGlobal || isLanding ? <ExchangeTool /> : null}
      <Cases />
      <UsersVoice />
      <XTCard moduleName="Home-CTA" showCoin={true} />
    </>
  );
};

export default Home;
