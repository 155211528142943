import { useRef, useState } from "react";
import cls from "classnames";
import { SwiperClass } from "swiper/react";
import { useModuleExposure, useTranslation } from "hooks";
import IconArrowRight from "components/SvgComponents/arrow-right.svg";
import IconArrowLeft from "components/SvgComponents/arrow-left.svg";
import HomeCarousel from "components/HomeCarousel";
import { HOME_USER_VOICE_PLACEHOLDER_BASE64 } from "constants/base64s";
import ImageAnimate from "components/ImageAnimate";
import type { UserDataType } from "./index";
import styles from "./web.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

const UsersVoice: React.FC<{ data: UserDataType[]; className: string }> = ({ data, className }) => {
  const { t } = useTranslation();
  const moduleExposureRef = useModuleExposure("Home-Customer testimonials");
  const swiperRef = useRef<SwiperClass | null>(null);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);

  const isEnd = currentActiveIndex >= data.length - 1;
  const isStart = currentActiveIndex <= 0;

  const renderController = () => {
    return (
      <div className={styles.controller}>
        <h2>{t("new-home-voice-main-title")}</h2>
        <div className={styles.buttonsWrapper}>
          <div
            className={cls(styles.toLeft)}
            style={isStart ? { backgroundColor: "#EBEBF0" } : {}}
            onClick={() => {
              if (!isStart) {
                swiperRef.current?.slidePrev();
              }
            }}
          >
            <span style={isStart ? { color: "#B3B2C2" } : {}}>
              <IconArrowLeft />
            </span>
          </div>
          <div
            className={cls(styles.toRight)}
            style={isEnd ? { backgroundColor: "#EBEBF0" } : {}}
            onClick={() => {
              if (!isEnd) {
                swiperRef.current?.slideNext();
              }
            }}
          >
            <span style={isEnd ? { color: "#B3B2C2" } : {}}>
              <IconArrowRight />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section ref={moduleExposureRef} className={cls("xt-safe-row", styles.wrapper, className)}>
      {renderController()}
      <div className={styles.customers}>
        <HomeCarousel ref={swiperRef} setCurrentActiveIndex={setCurrentActiveIndex} isEnd={isEnd} isStart={isStart}>
          {data.concat({} as UserDataType).map(({ customPicture, thinking, name, position }, index) => {
            return (
              <div key={index} className={styles.cardWrapper}>
                <ImageAnimate alt="" className={styles.customer} picture={customPicture} placeholder={HOME_USER_VOICE_PLACEHOLDER_BASE64} />
                <div className={styles.thinking}>
                  <p className={styles.content}>{thinking}</p>
                  <div>
                    <p className={styles.name}>{name}</p>
                    <p className={styles.position}>{position}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </HomeCarousel>
      </div>
    </section>
  );
};

export default UsersVoice;
