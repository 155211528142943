import cls from "classnames";
import { useModuleExposure } from "hooks";
import ImageAnimate from "components/ImageAnimate";
import { useCommonState } from "context";
import styles from "./index.module.scss";
import IconBingo from "components/SvgComponents/bingo.svg";
import { CSSProperties, isValidElement, ReactNode } from 'react';

interface IHeadBannerProps {
  conspicuous?: string;
  title: string;
  content?: string[] | string;
  image: string;
  imageH5?: string;
  right?: ReactNode,
  placeholder?: string;
  placeholderH5?: string;
  video?: string;
  loop?: boolean;
  actions?: () => React.ReactElement;
  reverse?: boolean;
  asHead?: boolean;
  moduleName?: string;
  fetchPriority?: "high" | "low" | "auto";

  rightStyle?: CSSProperties
}
const HeadBanner: React.FC<IHeadBannerProps> = ({
  title,
  content,
  image,
  imageH5,
  right,
  placeholder,
  placeholderH5,
  video,
  loop = false,
  actions,
  reverse = false,
  asHead = false,
  conspicuous,
  moduleName,
  fetchPriority,
  rightStyle
}) => {
  const moduleExposureRef = useModuleExposure(moduleName);
  const { isMobile } = useCommonState();

  let contentEle = null;
  if (typeof content === "string") {
    contentEle = <p className={cls(asHead && styles.descLarge, styles.desc)}>{content}</p>;
  } else if (Array.isArray(content)) {
    contentEle = (
      <div className={styles.contentsWrapper}>
        {content.map((item, index) => {
          return (
            <p key={index} className={cls(asHead && styles.descLarge, styles.desc)}>
              <span className={styles.iconWrapper}>
                <IconBingo className={styles.icon} />
              </span>
              <span>{item}</span>
            </p>
          );
        })}
      </div>
    );
  }
  return (
    <section ref={moduleExposureRef} className={styles.sectionWrapper}>
      <div
        className={cls("xt-safe-row", styles.headBannerWrapper, {
          [styles.reverse]: reverse,
          [styles.expand]: asHead,
        })}
      >
        <div className={styles.left}>
          {conspicuous && <p className={styles.conspicuous}>{conspicuous}</p>}
          {asHead ? <h1 className={styles.title}>{title}</h1> : <h2 className={styles.smallTitle}>{title}</h2>}
          {contentEle}
          {actions && <div className={styles.buttonsWrapper}>{actions()}</div>}
        </div>
        <div
          className={cls(styles.right, {
            [styles.rightAsHeader]: asHead,
          })}
          style={rightStyle}
        >
          {isValidElement(right) ? right :
            isMobile ? (
            <ImageAnimate
              style={{ width: "100%" }}
              picture={imageH5 || image}
              placeholder={placeholderH5 || imageH5 || image}
              alt={title}
              fetchPriority={fetchPriority}
            />
          ) : (
            <ImageAnimate style={{ width: "100%" }} picture={image} video={video} loop={loop} placeholder={placeholder || image} />
          )}
        </div>
      </div>
    </section>
  );
};

export default HeadBanner;
