import React, { isValidElement, ReactNode, useState } from 'react'
import _ from 'lodash'
import styles from './index.module.scss'
import NP from 'number-precision'
import { Button, Input, InputProps } from 'xt-design';
import { addComma, getFromCcyKeyValue, getToCcyKeyValue } from 'utils/exchangerate';
import ChangeCurrency from '../ChangeCurrency';
import { useExchangeRateDispatch, useExchangeRateState } from 'context/exchangeRate';
import { DEFAULT_AMOUNT } from 'constants/exchangeRate';
import { useTranslation } from 'next-i18next';
import { IconXUpdownconversion1 } from '@xt/react-icon'
import ERChart from '../ERChart';
import { ButtonType } from 'xt-design/es/Button/button';

interface Part {
  label: string
  left?: ReactNode | string
  right?: ReactNode
  className?: string
}

const Part = (props: Part) => {
  return <div className={props.className || styles.part}>
    <div className={styles.content}>
      <div className={styles.left}>
        {typeof (props?.left) === 'string' ?
          <Input className={styles.value} bordered={false} autoComplete='off' value={props?.left} disabled /> : props.left
        }
      </div>
      <div className={styles.right}>{isValidElement(props?.right) ? props.right : <span>{props?.right}</span>}</div>
    </div>
  </div>
}

const getToAmount = (fromCcyVal?: number, rate?: string) => {
  if (Infinity === fromCcyVal) return '∞'

  // 都有值才展示
  if (_.isNumber(fromCcyVal) && !! rate)
    return addComma(NP.times(fromCcyVal, Number(rate || '1'))) // 越南盾兑换美元，有8为小数
  return '--'
}

interface OwnProps {
  className?: string
}

const ExchangeCarousel = (props: OwnProps) => {
  const { className } = props
  const { t } = useTranslation()

  const { urlParsedCurrencies, currencyPairsData, amount, latestRatePoint } = useExchangeRateState()
  const { setUrlParsedCurrencies } = useExchangeRateDispatch()
  const [currencyPairValue, setCurrencyPairValue] = useState<number>(amount || DEFAULT_AMOUNT)

  const [fromCurrency, toCurrency] = urlParsedCurrencies

  const onFromCcyInputChange: InputProps['onChange'] = (e) => {
    // 如果输入为空，直接返回0
    if (e?.target?.value === '' || e?.target?.value === null) {
      return setCurrencyPairValue(0)
    }

    // 有效值
    const _fromCcyVal = Number(e?.target?.value?.replace(/,/g, ''))

    if (!_fromCcyVal || Infinity === _fromCcyVal || _.isNaN(_fromCcyVal)) return
    setCurrencyPairValue(_fromCcyVal)
  }

  const handleCurrencyChange = (val: string, isFrom?: boolean) => {
    if (isFrom)
      setUrlParsedCurrencies(val, toCurrency)
    else
      setUrlParsedCurrencies(fromCurrency, val)
  }

  return (
    <section className={`${styles['exchange-carousel-root']} ${className || ''}`} id={'exchange-rate'}>
      <div className={styles.inner}>
        <div className={styles.carousel}>
          <Part
            label={t('exchange-rate-carousel-from')}
            left={<Input onChange={onFromCcyInputChange} bordered={false} size='small'
                         value={addComma(currencyPairValue)} autoComplete='off'
                         className={styles.input} />}
            right={<ChangeCurrency options={getFromCcyKeyValue(currencyPairsData || []).filter(i => i.key !== latestRatePoint?.toCcy)}
                                   currentCurrencyIcon={latestRatePoint?.fromCcyIcon}
                                   currentCurrency={fromCurrency} onChange={e => handleCurrencyChange(e, true)} />}
          />
          <Button
            icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <IconXUpdownconversion1
                className={styles.icon} />
            </div>}
            type={'icon' as ButtonType}
            onClick={() => {setUrlParsedCurrencies(toCurrency, fromCurrency)}} />
          <Part
            label={t('exchange-rate-carousel-to')}
            left={(getToAmount(currencyPairValue, latestRatePoint?.rate))}
            // from币种可以兑换的货币
            right={<ChangeCurrency options={getToCcyKeyValue(fromCurrency, currencyPairsData || [])}
                                   currentCurrencyIcon={latestRatePoint?.toCcyIcon}
                                   currentCurrency={toCurrency} onChange={e => handleCurrencyChange(e)} />}
          />
        </div>
        <ERChart />
      </div>
    </section>)
}

export default ExchangeCarousel
