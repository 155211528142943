import cls from "classnames";
import styles from "./index.module.scss";
import IconBingo from "components/SvgComponents/bingo.svg";
import ImageAnimate from "components/ImageAnimate";
import { ADVANTAGE_PLACE_HOLDER_BASE64 } from "constants/base64s";

interface ISlideCardProps {
  title: string;
  content: string[] | string;
  image: string;
  imageH5: string;
  video?: string;
  active?: boolean;
  actions?: () => React.ReactElement;
}
const SlideCard: React.FC<ISlideCardProps> = ({ title, active, content, image, imageH5, video, actions }) => {
  let contentEle = null;
  if (typeof content === "string") {
    contentEle = <p className={cls(styles.desc)}>{content}</p>;
  } else {
    contentEle = (
      <div className={styles.contentsWrapper}>
        {Array.isArray(content) && content.map((item, index) => {
          return (
            <p key={index} className={cls(styles.desc)}>
              <span className={styles.iconWrapper}>
                <IconBingo className={styles.icon} />
              </span>
              <span>{item}</span>
            </p>
          );
        })}
      </div>
    );
  }
  return (
    <section className={cls(styles.sectionWrapper)}>
      <div className={cls("xt-safe-row", styles.slideCardWrapper)}>
        <div className={styles.left}>
          <div>
            <h2 className={styles.smallTitle}>{title}</h2>
            {contentEle}
          </div>
          {actions && <div className={styles.buttonsWrapper}>{actions()}</div>}
        </div>
        <div className={styles.right}>
          <ImageAnimate
            active={!!active}
            className={cls(styles.image).concat(" hidden-mobile")}
            picture={image}
            video={video}
            placeholder={ADVANTAGE_PLACE_HOLDER_BASE64} // 纯白色站位图
            alt=""
          />
          <ImageAnimate
            active={!!active}
            className={cls(styles.image).concat(" hidden-pc")}
            picture={imageH5}
            placeholder={ADVANTAGE_PLACE_HOLDER_BASE64} // 纯白色站位图
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default SlideCard;
