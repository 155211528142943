import { RatePointProp } from "types/exchangerate";
import { CurrencyInfoResponseProps } from 'apis/exchangerate';

/**
 * 获取 from - to 的信息
 * 如果 from 中没有 to，则返回 from 可以兑换的 _to 的第一个
 * 这样就可以确保，A-B始终有数据，B-A也有数据
 * @param data
 * @param fromKey
 * @param toKey
 */
export function getDataByKey(data: RatePointProp[], fromKey: string, toKey: string): RatePointProp | undefined {
  const _fromKey = fromKey.toUpperCase();
  const _toKey = toKey.toUpperCase();
  const _val = data.filter(i => i.fromCcy === _fromKey).filter(i => i.toCcy === _toKey);

  if (_val.length === 1) return _val[0]

  // 切换头，始终都会存在，只有切换A时，可能不会存在
  // 返回当前A可以兑换的B币种的第一个
  return data.filter(i => i.fromCcy === _fromKey)[0]
}

export function getToCcyKeyValue(fromKey: string, data: RatePointProp[]): { key: string, label: string, icon?: string }[] {
  const _fromKey = fromKey.toUpperCase();
  const _data = data.filter(i => i.fromCcy === _fromKey);
  return _data.map(i => ({ key: i.toCcy, label: i.toCcyName || '', icon: i.toCcyIcon }))
}

export function getFromCcyKeyValue(data: RatePointProp[], keyPre = 'from'): { key: string, label: string, icon?: string }[] {
  const keys: string[] = []
  const key = `${keyPre}Ccy` as 'fromCcy'
  const keyName = `${keyPre}CcyName` as 'fromCcyName'
  const iconName = `${keyPre}CcyIcon` as 'fromCcyIcon'
  return data.map(i => {
    if (keys.includes(i[key])) return null
    keys.push(i[key])
    return { key: i[key], label: i[keyName] || '', icon: i[iconName] }
  }).filter(Boolean) as { key: string, label: string }[]
}

// 解析字符串和数字
export function getNumber(val: unknown): number | undefined {
  if (typeof val === 'string') {
    const _val = Number(val.replace(/,/g, ''))
    if (isNaN(_val)) return undefined
    // TODO 无限大返回undefined
    if (Infinity === _val) return undefined
    return _val
  }
  return val as number
}

/**
 * @description 给数字或者金额千分位添加逗号
 * @param num
 * */
export function addComma(num: number | string): string {
  let reg = (typeof num === 'number' ? num.toString() : num).indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g;

  return num.toString().replace(reg, '$1,');
}

/**
 * 把两个接口的数据拼接起来，拼接货币名称
 */
export function formatCurrencyPairsData(pairData: RatePointProp[], info?: CurrencyInfoResponseProps[]) {
  return pairData.map(i => ({
    ...i,
    fromCcyName: info?.find(j => j.currencyCode === i.fromCcy)?.currencyName || '',
    toCcyName: info?.find(j => j.currencyCode === i.toCcy)?.currencyName || '',
    fromCcyIcon: info?.find(j => j.currencyCode === i.fromCcy)?.currencyIcon || '',
    toCcyIcon: info?.find(j => j.currencyCode === i.toCcy)?.currencyIcon || '',
  }))
}
