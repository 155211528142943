import React, { useRef, useState, useTransition } from 'react';
import styles from './index.module.scss';
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import { useQuery } from '@tanstack/react-query';
import { getGraphPresentRate, GraphPresentRateRequestProps } from 'apis/exchangerate';
import { useExchangeRateState } from 'context/exchangeRate';
import dayjs from 'dayjs';
import { useCommonState } from 'context';
import NP from 'number-precision';
import { Button, Empty, IconographNoData } from 'xt-design';
import { useTranslation } from 'next-i18next';

NP.enableBoundaryChecking(false)

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}

// 获取三个值数组，最大值、最小值、中间值
const getRange = (data: number[]) => {
  const max = Math.max(...data)
  const min = Math.min(...data)
  const middle = NP.round(NP.divide(max + min, 2), 4)
  return [min, middle, max]
}

const ERChart = () => {
  const { t } = useTranslation()
  const { isMobile } = useCommonState()
  const { latestRatePoint } = useExchangeRateState()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const [dateType, setDateType] = useState<GraphPresentRateRequestProps['type']>('MONTH')

  const { data, isLoading } = useQuery(
    ['getGraphPresentRate', dateType, latestRatePoint?.fromCcy, latestRatePoint?.toCcy, 'HK'],
    () => getGraphPresentRate({
      fromCcy: latestRatePoint!.fromCcy,
      toCcy: latestRatePoint!.toCcy,
      type: dateType,
      siteCode: 'HK'
    }), { enabled: !!latestRatePoint, staleTime: 120, cacheTime: 120, keepPreviousData: true })

  const { ratePointList: seriesData } = data || {}

  const changeDate = (type: GraphPresentRateRequestProps['type']) => {
    setDateType(type)
  }

  return (
    <section className={`${styles.root}`}>
      <h3 className={styles.h2}>
        1 {latestRatePoint?.fromCcy} = {latestRatePoint?.rate} {latestRatePoint?.toCcy}
      </h3>
      {
        !seriesData?.length ? <Empty iconographData={<IconographNoData />} description={t('exchange-rate-carousel-search-input-empty')} /> :
          <>
            <div className={styles.chartContainer}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'areaspline',
                    height: isMobile ? '57.8%' : '41.77%',
                    style: {
                      fontSize: isMobile ? '3.2vw' : '15px',
                      color: '#696680',
                      padding: 0
                    }
                  },
                  title: {
                    text: ''
                  },
                  plotOptions: {
                    series: {
                      marker: {
                        enabled: false,
                        radius: 7, // 设置圆点的半径
                        fillColor: 'white', // 设置圆点的填充颜色
                        lineWidth: 6, // 设置圆点的边框宽度
                        lineColor: 'orange', // 设置圆点的边框颜色
                        states: {
                          hover: {
                            enabled: true,
                            fillColor: '#FF7A00', // 设置悬浮时圆点的填充颜色
                            lineColor: '#FFF', // 设置悬浮时圆点的边框颜色
                            lineWidth: 6, // 设置悬浮时圆点的边框宽度
                          },
                        }
                      },
                    }
                  },
                  tooltip: {
                    backgroundColor: 'transparent',   // 背景颜色
                    animation: true,            // 是否启用动画效果
                    style: {                      // 文字内容相关样式
                      padding: 0,
                    },
                    useHTML: true,
                    headerFormat: '',            // 标题格式
                    pointFormat: `<div id='er-tooltip'><span id='er-tooltip-first'>{point.custom.tooltip}</span><span id='er-tooltip-sec'>{point.custom.extraTimeFormat}</span></div>`,
                    crosshairs: false
                  },
                  legend: { enabled: false },
                  credits: { enabled: false },
                  exporting: { enabled: false },
                  series: [{
                    lineColor: '#FF7B00',
                    color: {
                      linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                      },
                      stops: [
                        [0, 'rgba(235, 96, 37, 0.00)'],
                        [1, 'rgba(235, 120, 37, 0.12)']
                      ]
                    },
                    threshold: null,
                    data: seriesData?.map((item) => ({
                      y: Number(item.rate),
                      custom: {
                        extraTimeFormat: dayjs(item?.rateTime).format('DD MMM YYYY, HH:mm'),
                        tooltip: `1 ${item?.fromCcy} = ${item?.rate} ${item?.toCcy}`,
                      }
                    })),
                  }],
                  xAxis: {
                    visible: true,
                    lineColor: '#F1F5F9', // X轴刻度线的颜色
                    tickWidth: 0,         // X轴刻度线的宽度
                    tickLength: 0,    // X轴刻度线的长度
                    categories: seriesData?.map((item) => item.rateTime),
                    labels: {
                      x: -10,
                      rotation: 360,
                      formatter: ({ value, isFirst, isLast }: { value: string, isFirst: boolean, isLast: boolean }) => {
                        if (!(isFirst || isLast)) return null
                        switch (dateType) {
                          case 'DAY':
                            // @ts-ignore
                            return dayjs(value).format('HH:mm');
                          case 'WEEK':
                            // @ts-ignore
                            return dayjs(value).format('MM-DD');
                          default:
                            // @ts-ignore
                            return dayjs(value).format('MM-DD');
                        }
                      },
                      style: {
                        color: '#696680',
                        width: 40
                      },
                    },
                    useHTML: true,
                    title: {
                      text: '',
                    },
                    // className: styles.chartYAxis,
                    crosshair: {
                      color: 'rgba(0, 0, 0, 0.1)', // 设置十字线的颜色
                      width: 1,
                      dashStyle: 'Dash' // 设置虚线样式
                    }
                  },
                  yAxis: {
                    title: { text: '', },
                    // tickPositions,
                    tickPixelInterval: 110,
                    allowDecimals: true,
                    className: styles.chartYAxis,
                    gridLineWidth: 1,
                    gridLineColor: '#F1F5F9',
                    style: {
                      color: '#696680'
                    },
                    crosshair: {
                      color: 'rgba(0, 0, 0, 0.1)', // 设置十字线的颜色
                      width: 1,
                      dashStyle: 'Dash' // 设置虚线样式
                    }
                  }
                }}
                ref={chartComponentRef}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button className={`${styles.button} ${dateType === 'DAY' ? styles.active : ''}`} onClick={() => changeDate('DAY')}>Today</Button>
              <Button className={`${styles.button} ${dateType === 'WEEK' ? styles.active : ''}`} onClick={() => changeDate('WEEK')}>Last 7
                days</Button>
              <Button className={`${styles.button} ${dateType === 'MONTH' ? styles.active : ''}`} onClick={() => changeDate('MONTH')}>Last 30
                days</Button>
            </div>
          </>
      }
    </section>
  );
};

export default ERChart;

