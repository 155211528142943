import cls from "classnames";
import { useModuleExposure, useGoLogin, useTranslation } from "hooks";
import { useUserAgentState, useCommonState } from "context";
import { Button } from "components/Button";
import { XT_CARD_LOGO } from "constants/images";
import styles from "./index.module.scss";
import ContactUSButton from "components/CusButton";
import { ContactUsLink } from "components/Home";
import { XTImage } from "components/XTImage";
import { CusBtnSourceEnum } from "../../constants/enum";

interface IXTCardProps {
  moduleName?: string;
  showCoin?: boolean;
}

const XTCard: React.FC<IXTCardProps> = ({ moduleName, showCoin }) => {
  const { t } = useTranslation();
  const { isLanding, isGlobal } = useCommonState();
  const { isMobile } = useUserAgentState();
  const handleLogin = useGoLogin();
  const moduleExposureRef = useModuleExposure(moduleName);

  return (
    <div ref={moduleExposureRef} className={styles.container} style={{ backgroundColor: "#F5F5F7" }}>
      <section className={cls("xt-safe-row", styles.xtCardWrapper)}>
        <div className={styles.cardPlaceHolder} />
        <XTImage className={styles.cardLogo} alt="" src={XT_CARD_LOGO} />
        <div className={styles.xtCard}>
          <div className={styles.contentWrapper}>
            <h2>{t("xt-cta-card-title")}</h2>
            <p className={styles.text}>{t("xt-cta-card-text-line1")}</p>
            <p className={styles.text}>{t("xt-cta-card-text-line2")}</p>
          </div>
          <div className={styles.buttonsWrapper}>
            {isLanding || !isGlobal ? (
              <ContactUSButton
                style={{ borderRadius: "99px", background: "#fff", border: "1px solid #838099", color: "#000" }}
                source={isLanding ? CusBtnSourceEnum.HOME_LANDING : ""}
              />
            ) : (
              <>
                <Button className={cls(styles.btn, styles.contactUs)}>
                  <ContactUsLink>{t("new-home-contact-us")}</ContactUsLink>
                </Button>
                <Button className={cls(styles.btn, styles.signUp)} onClick={handleLogin}>
                  {t("common-sign-up-now")}
                </Button>
              </>
            )}
          </div>
        </div>
      </section>
      {showCoin && isMobile === false && (
        <>
          <XTImage src="https://static.xtransfer.com/boss/static/1_7b39bd64d14cf878.png" className={cls(styles.topleft, styles.coin)} alt="coin" />
          <XTImage src="https://static.xtransfer.com/boss/static/3_ca5adf93fe360455.png" className={cls(styles.topright, styles.coin)} alt="coin" />
          <XTImage src="https://static.xtransfer.com/boss/static/2_a848350d673d5e71.png" className={cls(styles.bottomleft, styles.coin)} alt="coin" />
          <XTImage
            src="https://static.xtransfer.com/boss/static/4_0bc8c23b28edf68a.png"
            className={cls(styles.bottomright, styles.coin)}
            alt="coin"
          />
        </>
      )}
    </div>
  );
};

export default XTCard;
