import cls from "classnames";
import { useUserAgentState } from "context";
import { useModuleExposure, useTranslation } from "hooks";
import ServiceCard from "components/HomeServiceCard";
import {
  INTERNATIONAL_BUSINESS_WALLET,
  INTERNATIONAL_SERVICE_COLLECTION,
  INTERNATIONAL_SERVICE_PAYMENT,
  INTERNATIONAL_SERVICE_EXCHANGE,
} from "constants/router";
import styles from "./index.module.scss";

const Services = () => {
  const { isMobile } = useUserAgentState();
  const { t } = useTranslation();
  const moduleExposureRef = useModuleExposure("Home-Products");

  const cardsData: Array<{
    title: string;
    content: string | string[];
    picture: string;
    hoverPicture: string;
    mobilePicture: string;
    mobileHoverPicture: string;
    dest: string;
  }> = [
    {
      title: t("new-home-service-wallet-title"),
      content: t("new-home-service-wallet-content"),
      picture: "https://static.xtransfer.com/boss/static/wallet_20661a79a8dcbd27.png",
      hoverPicture: "https://static.xtransfer.com/boss/static/wallet-y_9cfe6dac1ea82ac6.png",
      mobilePicture: "https://static.xtransfer.com/boss/static/h5-wallet_fda344f5957953c1.png",
      mobileHoverPicture: "https://static.xtransfer.com/boss/static/h5-wallet-y_6fe26397f2a5bf5e.png",
      dest: INTERNATIONAL_BUSINESS_WALLET,
    },
    {
      title: t("new-home-service-add-title"),
      content: t("new-home-service-add-content"),
      picture: "https://static.xtransfer.com/boss/static/add-money_e54db786f1f9c51c.png",
      hoverPicture: "https://static.xtransfer.com/boss/static/add-y_630ab17d94364363.png",
      mobilePicture: "https://static.xtransfer.com/boss/static/h5-add_be04834981c678cc.png",
      mobileHoverPicture: "https://static.xtransfer.com/boss/static/h5-add-y_00818a546ea1e60e.png",
      dest: INTERNATIONAL_SERVICE_COLLECTION,
    },
    {
      title: t("new-home-service-receive-title"),
      content: t("new-home-service-receive-content"),
      picture: "https://static.xtransfer.com/boss/static/receive-money_11183f9c2b120d6c.png",
      hoverPicture: "https://static.xtransfer.com/boss/static/send-y_aa1604618ba8573d.png",
      mobilePicture: "https://static.xtransfer.com/boss/static/h5-receive_765f6525769e90cd.png",
      mobileHoverPicture: "https://static.xtransfer.com/boss/static/h5-receive-y_1db1f01e52f9927e.png",
      dest: INTERNATIONAL_SERVICE_COLLECTION,
    },
    {
      title: t("new-home-service-send-title"),
      content: [
        t("new-home-service-exchange-content-line1"),
        t("new-home-service-exchange-content-line2"),
        t("new-home-service-exchange-content-line3"),
      ],
      picture: "https://static.xtransfer.com/boss/static/send-money_1b94150860f9b0e0.png",
      hoverPicture: "https://static.xtransfer.com/boss/static/receive-y_6cfe8ad84e4ce2b3.png",
      mobilePicture: "https://static.xtransfer.com/boss/static/h5-send_52dc5358a7751380.png",
      mobileHoverPicture: "https://static.xtransfer.com/boss/static/h5-send-y_4d4668212518f4e7.png",
      dest: INTERNATIONAL_SERVICE_PAYMENT,
    },
    {
      title: t("new-home-service-exchange-title"),
      content: t("new-home-service-exchange-content"),
      picture: "https://static.xtransfer.com/boss/static/exchange_86b85a6994f83dbe.png",
      hoverPicture: "https://static.xtransfer.com/boss/static/exchange-y_3bf8f64803042579.png",
      mobilePicture: "https://static.xtransfer.com/boss/static/h5-exchange_9f28c114b1869553.png",
      mobileHoverPicture: "https://static.xtransfer.com/boss/static/h5-exchange-y_993cf9492447d88b.png",
      dest: INTERNATIONAL_SERVICE_EXCHANGE,
    },
  ];
  return (
    <section ref={moduleExposureRef} className={cls(styles.container, "xt-safe-row")}>
      <h2 className={styles.title}>{t("new-home-service-main-title")}</h2>
      <div className={styles.services}>
        {isMobile ? (
          <ul className={cls(styles.firstLine)}>
            {cardsData.map((cardInfo, index) => (
              <ServiceCard key={index} className={styles.serviceItem} data={cardInfo} />
            ))}
          </ul>
        ) : (
          <div>
            <ul className={styles.firstLine}>
              {cardsData.slice(0, 2).map((cardInfo, index) => (
                <ServiceCard key={index} className={styles.serviceItem} data={cardInfo} ratio="43%" />
              ))}
            </ul>
            <ul className={styles.secondLine}>
              {cardsData.slice(2).map((cardInfo, index) => (
                <ServiceCard key={index} className={styles.serviceItem} data={cardInfo} ratio="60.64%" />
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Services;
