import cls from "classnames";
import { useModuleExposure, useTranslation } from "hooks";
import styles from "./mobile.module.scss";
import CarouselMobile from "components/CarouselMobile";
import type { UserDataType } from "./index";
import { XTImage } from "components/XTImage";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";

const UsersVoice: React.FC<{ data: UserDataType[]; className: string }> = ({ data, className, ...props }) => {
  const { t } = useTranslation();
  const moduleExposureRef = useModuleExposure("Home-Customer testimonials");
  return (
    <section ref={moduleExposureRef} className={cls(styles.wrapper, className)}>
      <div className={styles.titleWrapper}>
        <h2>{t("new-home-voice-main-title")}</h2>
      </div>
      <CarouselMobile>
        {data.map(({ avatar, thinking, name, position }, index) => {
          return (
            <div className={styles.customCard} key={index}>
              <XTImage className={styles.customer} src={avatar} alt="" />
              <div className={styles.thinking}>
                <p className={styles.content}>{thinking}</p>
                <div>
                  <p className={styles.name}>{name}</p>
                  <p className={styles.position}>{position}</p>
                </div>
              </div>
            </div>
          );
        })}
      </CarouselMobile>
    </section>
  );
};

export default UsersVoice;
