import Link from "next/link";
import { useRouter } from "next/router";
import cls from "classnames";
import { useUserAgentState } from "context";
import { useTranslation } from "hooks";
import { Button } from "components/Button";
import { XTImage } from "components/XTImage";
import IfElse from "components/IfElse";
import { getPathWithQuery, getPathWithSite } from "utils/route";
import styles from "./index.module.scss";
import IconGuideRight from "components/SvgComponents/guide-right.svg";
import { SitesEnum } from "constants/regions";

interface CardData {
  title: string;
  content: string | string[];
  picture: string;
  hoverPicture: string;
  mobilePicture: string;
  mobileHoverPicture: string;
  dest: string;
}
const ServiceCard: React.FC<{ className?: string; data: CardData; ratio?: string }> = ({ className, data, ratio }) => {
  const { title, content, picture, hoverPicture, mobilePicture, mobileHoverPicture, dest } = data;
  const { isMobile } = useUserAgentState();
  const { t } = useTranslation();
  const { asPath, query } = useRouter();
  const { site } = query as { site: SitesEnum };

  let contentEle = <p className={styles.content}>{content}</p>;
  if (Array.isArray(content)) {
    contentEle = (
      <>
        {content.map((item, index) => (
          <p key={index} className={cls(styles.content)}>
            {item}
          </p>
        ))}
      </>
    );
  }

  const goContactUs = () => {
    if (!window || isMobile) return;
    window.location.href = getPathWithQuery({ asPath, toPath: dest });
  };

  return (
    <div className={cls(styles.serviceCardWrapper, className)} onClick={goContactUs}>
      <div className={styles.contentWrapper}>
        <h3 className={styles.cardTitle}>{title}</h3>
        {contentEle}
        <Button type="primary" className={styles.button}>
          <Link href={getPathWithQuery({ asPath, toPath: getPathWithSite(site, dest) })}>{t("common-learn-more")}</Link>
          {!isMobile && <IconGuideRight style={{ marginLeft: "4px" }} />}
        </Button>
      </div>
      <div className={styles.image} style={{ paddingTop: ratio }}>
        <IfElse
          if={!isMobile}
          else={
            <>
              <XTImage className={styles.normalPicture} alt="" src={mobilePicture} />
              <XTImage className={styles.hoverPicture} alt="" src={mobileHoverPicture} />
            </>
          }
        >
          <XTImage className={styles.normalPicture} alt="" src={picture} />
          <XTImage className={styles.hoverPicture} alt="" src={hoverPicture} />
        </IfElse>
      </div>
    </div>
  );
};

export default ServiceCard;
